<template>
  <div class="card card-body shadow-sm csv_upload">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <form class="form-horizontal" v-on:submit.prevent="passes(uploadFile)">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("invoice_receipt.mansion_name") }} <span
                  class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-6 col-sm-6">
                  <app-select
                    name="apartment_id"
                    rules="required"
                    :options-data="meta.apartments"
                    v-model="entry.apartment_id"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("invoice_receipt.customer_name") }} <span
                  class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-select
                    name="顧客"
                    rules="required"
                    :options-data="meta.customers"
                    v-model="entry.customer_id"
                  />
                </div>
              </div>

              <div class="form-group row c_filter_area">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label custom-month-picker-label">{{ $t("invoice_receipt.month") }} </label>
                <div class="col-xl-5 col-lg-6 col-sm-6">
                  <month-picker-input
                    ref="month"
                    lang="ja"
                    :class="'c_sale_picker month_invoice'"
                    :default-month="selectMonth.month"
                    :default-year="selectMonth.year"
                    @change="changeMonth"
                    :show-year="true"
                    input-pre-filled
                    date-format="%Y年%n"
                  >
                  </month-picker-input>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("invoice_receipt.type") }} </label>
                <div class="col-xl-5 col-lg-6 col-sm-6 custom-radio-option">
                  <app-radio v-for="(item, index) in filters.typeInvoice"
                             :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                             :val="item.id" v-model="filters.file_type.value" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("invoice_receipt.office_code") }} </label>
                <div class="col-xl-5 col-lg-6 col-sm-6 custom-radio-option">
                  <app-radio v-for="(item, index) in filters.officeCode"
                             :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                             :val="item.id" v-model="filters.office_code.value"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("invoice_receipt.file_name") }}
                <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-6 col-sm-6">
                  <input type="file"
                         :name="filters.file_import.name"
                         class="custom-file-input"
                         id="inputGroupFile01"
                         aria-describedby="inputGroupFileAddon01"
                         accept="pdf"
                         @change="invoiceChangeFile()"
                         ref="importFile"
                  />
                  <label class="custom-file-label" for="inputGroupFile01"> {{importFile}}</label>
                  <label v-if="importFile == 'Choose file' && submit == true" class="error">
                    ファイルを入力してください。
                  </label>
                </div>
              </div>

              <div class="card-footer row justify-content-end gr-button">
                <button type="button" class="btn btn-default m-r-10" :disabled="!valid && validated"  @click="handlerCancelButton">
                  {{ $t('common.cancel') }}
                </button>
                <button class="btn btn-primary" :disabled="!valid && validated" type="submit" @click="customerCheckRequire()">
                  {{ $t("common.Upload execution") }}
                </button>
              </div>

            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import {createHmac} from 'crypto';
import AppRadio from "../../../components/forms/AppRadio";
import AppCheckbox from "../../../components/forms/AppCheckbox";
import {MonthPickerInput} from 'vue-month-picker'
import { __redirect } from '@utils';
import {ROUTES} from "@constants";

export default {
  name: "InvoiceReceipt",

  components: {
    AppCheckbox,
    AppRadio,
    MonthPickerInput
  },

  data() {
    const id = this.$route.params.id
    return {
      entry: {
        customer_id: 999999,
      },
      submit: false,
      disable_upload: false,
      selectMonth: {
        month: this.$route.query['filters.month.equal'] ? moment(this.$route.query['filters.month.equal']).month() + 1 : moment().month() + 1,
        year: this.$route.query['filters.month.equal'] ? moment(this.$route.query['filters.month.equal']).year() : moment().year(),
      },
      importFile: 'Choose file',
      filters: {
        apartment_id_equal: {
          name: "apartment_id",
          condition: "equal",
          label: this.$t("common.mansion")
        },
        customer_id_equal: {
          name: "customer_id",
          condition: "equal",
          label: this.$t("common.mansion")
        },
        file_import: {
          name: "file_import",
          condition: "equal",
          label: this.$t("csv_upload.file_select")
        },
        file_type: {
          name: "file_type",
          condition: "equal",
          label: this.$t("csv_upload.file_type"),
          value: 0
        },
        office_code: {
          value: 1
        },
        month: {
          name: "date",
          condition: "equal",
          label: this.$t("csv_upload.date")
        },
        typeInvoice: [
          {
            id: 0,
            name: "請求書"
          },
          {
            id: 1,
            name: "領収書"
          },
        ],
        officeCode: [
          {
            id: 1,
            name: "エボ利用明細",
          },
          {
            id: 2,
            name: "管理組合利用明細"
          },
        ]
      },
      meta: {
        apartments: []
      },
    };
  },
  watch: {
    'entry.apartment_id': function (value, oldValue) {
      if (value != oldValue && value != '') {
        this.getFacilityList(value)
      }
    }
  },

  mounted: async function () {
    let responseBookMasters = await this.$request.get(this.ENDPOINT.UI_HELPER_BOOK_MASTERS);
    if (!responseBookMasters.hasErrors()) {
      let apartments = responseBookMasters.data.apartments;
      apartments.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      this.meta = {
        apartments: apartments,
        customers: [
          {id: null, name: this.$t('common.choose')},
        ],
      }
    }
  },

  methods: {
    getFacilityList(apartment_id) {
      if (apartment_id) {
        this.$request.get(this.ENDPOINT.CUSTOMER_LIST_BY_ID(apartment_id)).then(res => {
          let facilities = res.data.data;
          if (res.data.data) {
            facilities.unshift({id: null, name: this.$t('common.choose')});
            this.meta.customers = facilities
          }
        })
      }
    },
    customerCheckRequire() {
      this.submit = true
      if (!this.filters.apartment_id_equal.value
        || !this.$refs.importFile.value
        || !this.filters.customer_id_equal.value
        || !this.filters.file_type.value
        || !this.filters.date.value
      ) {
        this.disable_upload = true;
      } else {
        this.disable_upload = false;
      }
    },
    async changeMonth(dataSelect) {
      this.selectMonth = {
        month: dataSelect.monthIndex,
        year: dataSelect.year,
      }
      let currentMonth = dataSelect.year + '/' + dataSelect.monthIndex + '/01'
      this.filters.month.value = currentMonth
    },
    invoiceChangeFile() {
      let invoiceFilePath = this.$refs.importFile.value;
      let invoiceFileName = invoiceFilePath.split('\\');
      if (invoiceFilePath !== '' && invoiceFileName.length !== 0) {
        this.importFile = invoiceFileName[invoiceFileName.length-1];
      } else {
        this.importFile = 'Choose file';
      }

      this.customerCheckRequire();
    },
    async uploadFile() {
      this.submit = true
      let form = new FormData();
      let file = this.$refs.importFile.files[0]
      form.append('file', file);
      form.append('apartment_id', this.entry.apartment_id);
      form.append('customer_id', this.entry.customer_id);
      form.append('month', this.filters.month.value);
      form.append('file_type', this.filters.file_type.value);
      form.append('office_code', this.filters.office_code.value);
      form.append('file_name', this.importFile);

      const res = await this.$request.post(this.ENDPOINT.INVOICE_RECEIPT_UPLOAD, form);
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("csv_upload.upload_success"));
        this.redirectDefaultValue()
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      }
    },
    async redirectDefaultValue() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.INVOICE_RECEIPT_LIST,
        query : {}
      });
    },
    handlerCancelButton() {
      if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.INVOICE_RECEIPT_LIST) {
        this.__redirectBack()
      } else {
        this.redirectDefaultValue()
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.month_invoice {
  border: 1px solid #9BA2AA;
  border-radius: 5px;
  width: 250px !important;
}
.csv_upload .custom-file-input ~ .custom-file-label::after {
  content: "参照";
  height: calc(2.25rem + 4px);
  padding: .375rem 1.5rem;
  background-color: #007bff;
  color: white;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}
.csv_upload .custom-file-input {
  cursor: pointer;
}
.csv_upload .custom-file-label {
  height: calc(2.25rem + 4px);
  border-color: #9ba2aa;
  white-space: pre;
  word-wrap: normal;
  text-overflow: ellipsis;
  padding-right: 100px;
  padding-left: .46875rem;
  margin-left: 7px;
}
.csv_upload .h6 {
  font-size: 13px;
  color: #7a7474;
}
.custom-month-picker-label {
  top: 10px
}
.custom-radio-option {
  padding-top: 5px;
}

</style>
